<template>
    <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <header class="header-custom">
        <b-container fluid>
          <b-row>
            <b-col cols="12" md="12" lg="12">
              <b-row>
                <b-col cols="6" md="4" lg="4">
                  <div style="width:100%;height:60px;display:flex;justify-content:flex-start;align-items:center;background-color:;">
                    <h2 style="margin:0;color:#fff">SIMKES Kabupaten Kudus</h2>
                  </div>
                </b-col>
                <b-col cols="6" md="8" lg="8">
                  <div style="width:100%;height:60px;display:flex;justify-content:flex-end;align-items:center;background-color:;">
                    <h2 style="margin:0;color:#fff">{{ tanggal_berlangsung }}</h2>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </header>
  
      <section style="padding-top: 20px; padding-bottom: 0">
        <b-container fluid>
          <b-row>
            <b-col cols="12" md="12" lg="12">
                <b-card>
                    <b-row>
                        <b-col cols="6" md="6" lg="6">
                            <b-row>
                                <b-col cols="12" md="12" lg="12">
                                    <table >
                                        <tr>
                                            <td style="width: 20%; font-size:40px;font-weight:bold;border:hidden !important">Poli</td>
                                            <td style="width: 80%;font-size:40px;font-weight:bold;border:hidden !important">: {{nama_poli}}</td>
                                        </tr>
                                    </table>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6" md="6" lg="6">
                                    <b-card style="height:400px;" header-tag="header" header-bg-variant="success" align="center">
                                        <template #header>
                                            <h4><strong>NOMOR ANTRIAN</strong></h4>
                                        </template>
                                        <p style="font-size:150px;font-weight: bold;">{{no_antrian}}</p>
                                    </b-card>
                                </b-col>
                                <b-col cols="6" md="6" lg="6">
                                    <b-card style="height:400px" header-tag="header" header-bg-variant="success" align="center">
                                        <template #header>
                                            <h4><strong>SISA ANTRIAN</strong></h4>
                                        </template>
                                        <p style="font-size:150px;font-weight: bold;">{{sisa_antrian}}</p>
                                    </b-card>
                                </b-col>
                            </b-row>
                            <!-- <b-card style="height:600px" footer-tag="footer" align="center">
                                <b-img :src="src" fluid style="width:100%;height:500px"></b-img>
                                <template #footer>
                                    <h3><strong>Dr. Joko Supri</strong></h3>
                                </template>
                            </b-card> -->
                            <!-- <b-card style="height:287px" header-tag="header" header-bg-variant="success">
                                <template #header>
                                    <center><h4><strong>POLI</strong></h4></center>
                                </template>
                                <b-card-text>
                                    <p style="font-size:50px;font-weight: bold;">POLI ANAK</p>
                                </b-card-text>
                            </b-card>
                            <b-card style="height:287px" header-tag="header" header-bg-variant="success">
                                <template #header>
                                    <center><h4><strong>DOKTER</strong></h4></center> 
                                </template>
                                <p style="font-size:50px;font-weight: bold;">Dr. Joko Supriyono</p>
                            </b-card> -->
                        </b-col>
                        <!-- <b-col cols="3" md="3" lg="3">
                            <b-card style="height:287px" header-tag="header" header-bg-variant="success" align="center">
                                <template #header>
                                    <h4><strong>JUMLAH ANTRIAN</strong></h4>
                                </template>
                                <p style="font-size:100px;font-weight: bold;">29</p>
                            </b-card>
                            <b-card style="height:287px" header-tag="header" header-bg-variant="success" align="center">
                                <template #header>
                                    <h4><strong>SISA ANTRIAN</strong></h4>
                                </template>
                                <p style="font-size:100px;font-weight: bold;">9</p>
                            </b-card>
                        </b-col> -->
                        <b-col cols="6" md="6" lg="6">
                            <b-card style="height:600px" header-tag="header" header-bg-variant="success" align="center">
                                <template #header>
                                    <h4><strong>DAFTAR ANTRIAN</strong></h4>
                                </template>
                                <b-row>
                                  <b-col cols="12" md="12" lg="12">
                                    <div class="table">
                                      <auto-scroll>
                                        <b-table
                                          :items="items"
                                          :fields="fields"
                                          responsive
                                          small
                                          striped
                                          hover
                                          borderless
                                          :current-page="currentPage"
                                          :per-page="perPage"
                                          style="font-weight:bold; font-size:25px;"
                                        >
                                        </b-table>
                                      </auto-scroll>
                                      
                                    </div>
                                  </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12" lg="12">
                <marquee style="font-size:30px;font-weight:bold;color: white;" bgColor=#5c5655>SELAMAT DATANG DI {{ nama_puskesmas }}</marquee>
            </b-col>
          </b-row>
        </b-container>
      </section>
    </div>
</template>
<script>
  // import axios from "axios";
  // import { ipBackend } from "@/ipBackend";
  import moment from "moment";
  moment.locale("ID");
  export default {
    name: "display_loket",
    data() {
      return {
        poli: [],
        poli_id: "",
        nama_poli: "",
        nama_dokter: "",
        kuota: "",
        sisa_antrian: "",
        no_antrian: "",
        items: [],
        fields: [

          {
            key: "no_antrian",
            label: "No. Antrian",
            sortable: false,
            sortDirection: "desc",
            class: "text-center",
          },

          {
            key: "nama_lengkap",
            label: "Nama Pasien",
            sortable: false,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "statusnya",
            label: "Status",
            sortable: false,
            sortDirection: "desc",
            class: "text-left",
          },
        ],
        tanggal_berlangsung : "",
        src: "",
        no_panggilan: "",
        kode_panggilan: "",
        currentPage: 1,
        perPage: 40,
        room_id: null,
      };
    },
    mounted() {
      this.getDatas();
      this.room_id = localStorage('idp')
      this.nama_puskesmas = localStorage.getItem('nama_puskesmas')
      this.$socket.emit("joinRoom", `${this.room_id}${this.poli_id}`); 
    },
    created() {
      this.$socket.on("connect", () => {
        this.$socket.emit("joinRoom", `${this.room_id}${this.poli_id}`); 
      })
      this.$socket.off("refresh_layar_poli")
      this.$socket.on("refresh_layar_poli", (data) => {
        console.log(data, "uhuyyyyyyyy");
        this.no_antrian = data.data.no_antrian
        this.sisa_antrian = data.data.sisa_antrian
        this.no_panggilan = `${data.data.antrian_no}`
        this.kode_panggilan = data.data.initial
        // this.cekLoket()
        this.getDatas();
        
      });
      this.$socket.off("refresh_admin_poli")
      this.$socket.on("refresh_admin_poli", (data) => {
        console.log(data, 'refresh admin');
        this.getDatas()
      })
      this.$socket.on('error', (data) => {
        console.log(data, 'ini error');
      })
    },
    methods: {
      cekLoket(){
        // let vm = this
        // console.log(x ,'ini x');
        // console.log(vm.master_loket, 'master loket');
        // vm.no_panggilan = `${x.antrian_no}`
        // vm.kode_panggilan = x.initial
        // for (let i = 0; i < vm.poli.length; i++) {
        //   let y = vm.poli[i];
        //   if(y.id == x.poli_id){
        //     vm.kode_poli = y.kdPoliBpjs
        //   }
        // }
        // vm.panggil()
      },
      async getDatas() {
        let vm = this;
        vm.items = []
        let tgl = moment(new Date()).format("YYYY-MM-DD");
        vm.tanggal_berlangsung = moment().format("dddd, DD MMMM YYYY")
        vm.poli_id = vm.$route.params.id
        this.$socket.emit("joinRoom", this.poli_id);
        let poli = await vm.$axios.post("/ms_poli/list");
        vm.poli = poli.data.data
        for (let i = 0; i < vm.poli.length; i++) {
          let x = vm.poli[i];
          if(x.ms_poli_id == vm.poli_id){
            vm.nama_poli = x.nama_poli
            vm.kode_poli = x.kode_poli_puskesmas
          }
        }
        
        // let poli_aktif = await axios.post(ipBackend + "antrian/listAntrianAktifPoli", {
        //   ms_poli_id : vm.poli_id,
        // })

        let list_antrian = await vm.$axios.post("/antrian/list", {
          ms_poli_id : vm.poli_id,
          tanggal_antrian: tgl,
          tipe_antrian: "poli"
        })
        vm.items = []
        for (let i = 0; i < list_antrian.data.data.length; i++) {
            let x = list_antrian.data.data[i];
            x.no_antrian = `${x.initial}${x.nomor_antrian}`;
            if(x.status_antrian == 1){
              x.statusnya = "Belum di proses"
              vm.items.push(x)
            }else if (x.status_antrian == 2){
              x.statusnya = "Sedang Proses"
              vm.items.push(x)
            }
            
          }
        console.log(list_antrian, 'ini list antrian');
        // console.log(poli_aktif, 'poli aktif');
        // vm.sisa_antrian = poli_aktif.data.sisa_antrian
      },
      kodenya(){
      let vm = this
      let kode = new Audio(
        require(`../../assets/sound/sound/${vm.kode_panggilan[0]}.wav`)
      );
      
      if(vm.kode_panggilan.length == 1){
        kode.play()
      }else if(vm.kode_panggilan.length == 2){
        let kode2 = new Audio(
          require(`../../assets/sound/sound/${vm.kode_panggilan[1]}.wav`)
        );
        kode.play()
        setTimeout(() => {
          kode2.play()
        }, 500);
      }
    },
    panggil() {
      this.sedang_manggil = true;
      this.sedang_proses = true
      let nomor_antrian = new Audio(
        require(`../../assets/sound/sound/nomor_antrian.wav`)
      );
      // let kode = new Audio(
      //   require(`../../assets/sound/sound/${this.kode_panggilan}.wav`)
      // );
      if (this.no_panggilan.length == 1) {
        let audio = new Audio(
          require(`../../assets/sound/sound/${this.no_panggilan}.wav`)
        );
        nomor_antrian.play();
        setTimeout(() => {
          this.kodenya()
          setTimeout(() => {
            audio.play();
            this.lokete();
          }, 1000);
        }, 2000);
      } else if (this.no_panggilan.length == 2) {
        if (this.no_panggilan == 10 || this.no_panggilan == 11) {
          let audio = new Audio(
            require(`../../assets/sound/sound/${this.no_panggilan}.wav`)
          );
          nomor_antrian.play();
          setTimeout(() => {
            this.kodenya()
            setTimeout(() => {
              audio.play();
              this.lokete();
            }, 1000);
          }, 2000);
        } else if (this.no_panggilan[0] == 1) {
          let kesatu = new Audio(
            require(`../../assets/sound/sound/${this.no_panggilan[1]}.wav`)
          );
          let kedua = new Audio(
            require(`../../assets/sound/sound/belas.wav`)
          );
          nomor_antrian.play();
          setTimeout(() => {
            this.kodenya()
            setTimeout(() => {
              kesatu.play();
              setTimeout(() => {
                kedua.play();
                this.lokete();
              }, 1000);
            }, 1000);
          }, 2000);
        } else if (this.no_panggilan[0] != 1) {
          if (this.no_panggilan[1] == 0) {
            let kesatu = new Audio(
              require(`../../assets/sound/sound/${this.no_panggilan[0]}.wav`)
            );
            let kedua = new Audio(
              require(`../../assets/sound/sound/puluh.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  this.lokete();
                }, 1000);
              }, 1000);
            }, 2000);
          } else {
            let kesatu = new Audio(
              require(`../../assets/sound/sound/${this.no_panggilan[0]}.wav`)
            );
            let kedua = new Audio(
              require(`../../assets/sound/sound/puluh.wav`)
            );
            let ketiga = new Audio(
              require(`../../assets/sound/sound/${this.no_panggilan[1]}.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    this.lokete();
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          }
        }
      } else if (this.no_panggilan.length == 3) {
        if (this.no_panggilan == 100) {
          let audio = new Audio(
            require(`../../assets/sound/sound/seratus.wav`)
          );
          nomor_antrian.play();
          setTimeout(() => {
            this.kodenya()
            setTimeout(() => {
              audio.play();
              this.lokete();
            }, 1000);
          }, 2000);
        } else if (this.no_panggilan[0] == 1) {
          if (
            (this.no_panggilan[1] == 1 && this.no_panggilan[2] == 0) ||
            (this.no_panggilan[1] == 1 && this.no_panggilan[2] == 1)
          ) {
            let kesatu = new Audio(
              require(`../../assets/sound/sound/seratus.wav`)
            );
            let kedua = new Audio(
              require(`../../assets/sound/sound/${this.no_panggilan[1]}${this.no_panggilan[2]}.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  this.lokete();
                }, 1000);
              }, 1000);
            }, 2000);
          } else if (this.no_panggilan[2] == 0) {
            let kesatu = new Audio(
              require(`../../assets/sound/sound/seratus.wav`)
            );
            let kedua = new Audio(
              require(`../../assets/sound/sound/${this.no_panggilan[1]}.wav`)
            );
            let ketiga = new Audio(
              require(`../../assets/sound/sound/puluh.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    this.lokete();
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          } else if (this.no_panggilan[1] == 1) {
            let kesatu = new Audio(
              require(`../../assets/sound/sound/seratus.wav`)
            );
            let kedua = new Audio(
              require(`../../assets/sound/sound/${this.no_panggilan[2]}.wav`)
            );
            let ketiga = new Audio(
              require(`../../assets/sound/sound/belas.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    this.lokete();
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          } else if (this.no_panggilan[1] == 0) {
            let kesatu = new Audio(
              require(`../../assets/sound/sound/seratus.wav`)
            );
            let kedua = new Audio(
              require(`../../assets/sound/sound/${this.no_panggilan[2]}.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  this.lokete();
                }, 1000);
              }, 1000);
            }, 2000);
          } else {
            let kesatu = new Audio(
              require(`../../assets/sound/sound/seratus.wav`)
            );
            let kedua = new Audio(
              require(`../../assets/sound/sound/${this.no_panggilan[1]}.wav`)
            );
            let ketiga = new Audio(
              require(`../../assets/sound/sound/puluh.wav`)
            );
            let keempat = new Audio(
              require(`../../assets/sound/sound/${this.no_panggilan[2]}.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    setTimeout(() => {
                      keempat.play();
                      this.lokete();
                    }, 1000);
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          }
        } else if (this.no_panggilan[0] != 1) {
          if (
            (this.no_panggilan[1] == 1 && this.no_panggilan[2] == 0) ||
            (this.no_panggilan[1] == 1 && this.no_panggilan[2] == 1)
          ) {
            let kesatu = new Audio(
              require(`../../assets/sound/sound/${this.no_panggilan[0]}.wav`)
            );
            let kedua = new Audio(
              require(`../../assets/sound/sound/ratus.wav`)
            );
            let ketiga = new Audio(
              require(`../../assets/sound/sound/${this.no_panggilan[1]}${this.no_panggilan[2]}.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    this.lokete();
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          } else if (this.no_panggilan[1] == 0 && this.no_panggilan[2] == 0) {
            let kesatu = new Audio(
              require(`../../assets/sound/sound/${this.no_panggilan[0]}.wav`)
            );
            let kedua = new Audio(
              require(`../../assets/sound/sound/ratus.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  this.lokete();
                }, 1000);
              }, 1000);
            }, 2000);
          } else if (this.no_panggilan[1] == 0) {
            let kesatu = new Audio(
              require(`../../assets/sound/sound/${this.no_panggilan[0]}.wav`)
            );
            let kedua = new Audio(
              require(`../../assets/sound/sound/ratus.wav`)
            );
            let ketiga = new Audio(
              require(`../../assets/sound/sound/${this.no_panggilan[2]}.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    this.lokete();
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          } else if (this.no_panggilan[2] == 0) {
            let kesatu = new Audio(
              require(`../../assets/sound/sound/${this.no_panggilan[0]}.wav`)
            );
            let kedua = new Audio(
              require(`../../assets/sound/sound/ratus.wav`)
            );
            let ketiga = new Audio(
              require(`../../assets/sound/sound/${this.no_panggilan[1]}.wav`)
            );
            let keempat = new Audio(
              require(`../../assets/sound/sound/puluh.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    setTimeout(() => {
                      keempat.play();
                      this.lokete();
                    }, 1000);
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          } else if (this.no_panggilan[1] == 1) {
            let kesatu = new Audio(
              require(`../../assets/sound/sound/${this.no_panggilan[0]}.wav`)
            );
            let kedua = new Audio(
              require(`../../assets/sound/sound/ratus.wav`)
            );
            let ketiga = new Audio(
              require(`../../assets/sound/sound/${this.no_panggilan[2]}.wav`)
            );
            let keempat = new Audio(
              require(`../../assets/sound/sound/belas.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    setTimeout(() => {
                      keempat.play();
                      this.lokete();
                    }, 1000);
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          } else {
            let kesatu = new Audio(
              require(`../../assets/sound/sound/${this.no_panggilan[0]}.wav`)
            );
            let kedua = new Audio(
              require(`../../assets/sound/sound/ratus.wav`)
            );
            let ketiga = new Audio(
              require(`../../assets/sound/sound/${this.no_panggilan[1]}.wav`)
            );
            let keempat = new Audio(
              require(`../../assets/sound/sound/puluh.wav`)
            );
            let kelima = new Audio(
              require(`../../assets/sound/sound/${this.no_panggilan[2]}.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    setTimeout(() => {
                      keempat.play();
                      setTimeout(() => {
                        kelima.play();
                        this.lokete();
                      }, 1000);
                    }, 1000);
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          }
        }
      }
      //    else if (this.no_panggilan.length == 4) {
      //     if (this.no_panggilan == 1000) {
      //       let audio = new Audio(
      //         require(`../../assets/sound/sound/seribu.wav`)
      //       );
      //       nomor_antrian.play();
      //       setTimeout(() => {
      //         audio.play();
      //         this.loket();
      //       }, 2000);
      //     } else if (this.no_panggilan[0] == 1) {
      //       if (this.no_panggilan[1] == 0 && this.no_panggilan[2] == 0) {
      //         let kesatu = new Audio(
      //           require(`../../assets/sound/sound/seribu.wav`)
      //         );
      //         let kedua = new Audio(
      //           require(`../../assets/sound/sound/${this.no_panggilan[3]}.wav`)
      //         );
      //         nomor_antrian.play();
      //         setTimeout(() => {
      //           kesatu.play();
      //           setTimeout(() => {
      //             kedua.play();
      //             this.loket();
      //           }, 1000);
      //         }, 2000);
      //       } else if (
      //         (this.no_panggilan[1] == 0 &&
      //           this.no_panggilan[2] == 1 &&
      //           this.no_panggilan[3] == 0) ||
      //         (this.no_panggilan[1] == 0 &&
      //           this.no_panggilan[2] == 1 &&
      //           this.no_panggilan[3] == 1)
      //       ) {
      //         let kesatu = new Audio(
      //           require(`../../assets/sound/sound/seribu.wav`)
      //         );
      //         let kedua = new Audio(
      //           require(`../../assets/sound/sound/${this.no_panggilan[2]}${this.no_panggilan[3]}.wav`)
      //         );
      //         nomor_antrian.play();
      //         setTimeout(() => {
      //           kesatu.play();
      //           setTimeout(() => {
      //             kedua.play();
      //             this.loket();
      //           }, 1000);
      //         }, 2000);
      //       }
      //       let kesatu = new Audio(
      //         require(`../../assets/sound/sound/seribu.wav`)
      //       );
      //       let kedua = new Audio(
      //         require(`../../assets/sound/sound/${this.no_panggilan[1]}.wav`)
      //       );
      //       let ketiga = new Audio(
      //         require(`../../assets/sound/sound/ratus.wav`)
      //       );
      //       let keempat = new Audio(
      //         require(`../../assets/sound/sound/${this.no_panggilan[2]}.wav`)
      //       );
      //       let kelima = new Audio(
      //         require(`../../assets/sound/sound/puluh.wav`)
      //       );
      //       let keenam = new Audio(
      //         require(`../../assets/sound/sound/${this.no_panggilan[3]}.wav`)
      //       );
      //       nomor_antrian.play();
      //       setTimeout(() => {
      //         kesatu.play();
      //         setTimeout(() => {
      //           kedua.play();
      //           setTimeout(() => {
      //             ketiga.play();
      //             setTimeout(() => {
      //               keempat.play();
      //               setTimeout(() => {
      //                 kelima.play();
      //                 setTimeout(() => {
      //                   keenam.play();
      //                   this.loket();
      //                 }, 1000);
      //               }, 1000);
      //             }, 1000);
      //           }, 1000);
      //         }, 1000);
      //       }, 2000);
      //     }
      //   }
    },
    lokete() {
      let loket = new Audio(require(`../../assets/sound/sound/di_poli.wav`));
      setTimeout(() => {
        loket.play();
        this.nama_loket();
      }, 1000);
    },
    nama_loket() {
      // let i = this.kode_poli;
      
      // let nama_loket = new Audio(
      //   require(`../../assets/sound/sound/${i}.wav`)
      // );
      
      setTimeout(() => {
        // nama_loket.play()
        this.sedang_manggil = false;
        if(this.on_process == false){
          this.sedang_proses = false
        }
      }, 1500);
    },
    },
  };
  </script>
  <style scoped>
  h5 {
    font-weight: bold;
  }
  .header-custom {
    width: 100%;
    height: 60px;
    /* background-color: #fff; */
    background-image: linear-gradient(to right, #2ecc71, #27ae60);

  }
  .header-custom-mobile {
    height: 100%;
    background-color: whitesmoke;
    /* height: 60px; */
  }
  .logo {
    display: flex;
    /* width: 100%; */
    height: 40px;
    align-items: center;
    justify-content: flex-end;
  }
  .card {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .profile,
  .kantor {
    display: flex;
    flex-direction: column;
  }
  .profile span {
    margin-bottom: 2%;
  }
  .kantor span {
    margin-bottom: 2%;
  }
  .table{
    height: 500px;
    overflow-y:auto;
  }

  .card-header{
    background-image: linear-gradient(to right, #2ecc71, #27ae60);
    color:#fff;
  }
  </style>
  